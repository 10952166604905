import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';

import isMobile from '../../services/isMobile';
import featureSwitch from '../../services/featureSwitch';
import { margins, RelativePosition, Container, Content } from '../../components/Home/v2/StyledComponents';
import HeaderPhoto from '../../components/Home/HeaderPhoto';
import MenuBar from '../../components/Home/MenuBar';
import HowItWorks from '../../components/Home/HowItWorks';
import WhyTilli from '../../components/Home/WhyTilli';
import TheyLoveTilli from '../../components/Home/TheyLoveTilli';
import PartnersPager from '../../components/Home/PartnersPager';
import Footer from '../../components/Home/Footer';
import BrandFooter from '../../components/Home/BrandFooter';
import ReferrerBanner from '../../components/Home/ReferrerBanner';

import photoSeparation from '../../assets/assurance-3.jpg';

const Title1 = styled.h1`
  color:white;
  position:absolute;
  z-index: -1;
`;

const BlockSeparator = styled.img`
  width: 100%;
  margin-top: ${margins.l};
`;

class Home extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
  }

  render() {
    const { referrer, landingVersion } = this.props;
    return (
      <Container>
        <Content full>
          <Title1>Tilli service de retouche à domicile à Paris, Marseille, Aix</Title1>
          {referrer && <ReferrerBanner referrer={referrer} />}
          <MenuBar landingVersion={!!landingVersion} />
          <RelativePosition>
            <HeaderPhoto landingVersion={landingVersion} />
          </RelativePosition>
          <Content>
            <HowItWorks />
            <WhyTilli />
          </Content>
          {featureSwitch('home_showFullPage') &&
            <div>
              <Content>
                <LazyLoad height={200} offset={1000} once>
                  <PartnersPager landingVersion={!!landingVersion} />
                </LazyLoad>
                {!isMobile() &&
                  <BlockSeparator
                    src={photoSeparation}
                    alt={'Un manteau ceintré sur à la taille.'}
                  />
                }
                <TheyLoveTilli />
              </Content>
              <Footer />
            </div>
          }
          {featureSwitch('home_showBrandFooter') &&
            <BrandFooter />
          }
        </Content>
      </Container>
    );
  }
}

Home.propTypes = {
  referrer: PropTypes.shape({}),
  landingVersion: PropTypes.string,
};

Home.defaultProps = {
  referrer: null,
  landingVersion: null,
};

export default Home;
